<template>
  <b-row>
    <b-col cols="3" class="no-padding">
      <meteoblue-multimodel place="idron"></meteoblue-multimodel>
    </b-col>
    <b-col cols="3" class="no-padding">
      <meteoblue-multimodel place="gourette"></meteoblue-multimodel>
    </b-col>
    <b-col cols="3" class="no-padding">
      <meteoblue-multimodel place="bareges"></meteoblue-multimodel>
    </b-col>
    <b-col cols="3" class="no-padding">
      <meteoblue-multimodel place="arrens"></meteoblue-multimodel>
    </b-col>
  </b-row>
</template>

<script>
import MeteoblueMultimodel from '@/components/MeteoblueMultimodel.vue';

export default {
  name: 'Comparaison',
  components: {
    MeteoblueMultimodel,
  },
};
</script>
